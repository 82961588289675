<template>
  <div class="disk-content-header">
    <!-- <OfficeNav>
      <div class="main">
        <div class="upload-btn" @click="uploadFiles" v-show="uploadFilesNav">
          <i class="upload-btn-icon"></i> 上传
        </div>
        <div class="create-dir" @click="addFile" v-show="addFiles">
          <i class="create-dir-icon"></i> 新建文件夹
        </div>
        <div class="intelli-analysis">
          <el-switch
            @change="switchValue"
            v-model="value"
            active-color="#4587ff"
            inactive-color="#bcbcbc"
          >
          </el-switch>
          <span>自动智能解析</span>
        </div>
        <div class="storage-detail">
          <div class="storage-proportion" ref="messageInfoTableRef">
            {{ nub }}
          </div>
        </div>
      </div>
      <div class="analysis-progress" v-if="progressDelay">
        解析中
        <el-progress :percentage="progress"></el-progress>
      </div>
    </OfficeNav> -->
    <div class="file-operate">
      <!-- <div class="upload-btn" @click="$emit('uploadFiles')" v-if="uploadFiles">
        <i class="upload-btn-icon"></i> 上传
      </div>
      <div class="create-dir" @click="$emit('addFiles')" v-if="addFiles">
        <i class="create-dir-icon"></i> 新建文件夹
      </div> -->
      <!-- <div
        class="create-dir"
        v-if="addFiles1 && this.$store.state.sidebarActive != 10"
      >
        <i class="share-dir-icon"></i> 分享
      </div>
      <div
        class="create-dir"
        v-if="addFiles1 && this.$store.state.sidebarActive != 10"
      >
        <i class="Download-dir-icon"></i> 下载
      </div> -->
      <div
        class="create-dir"
        @click="deletsFiles"
        v-if="
          addFiles1 &&
          this.$store.state.sidebarActive != 10 &&
          this.$parent.siderNum.name != '最近'
        "
      >
        <i class="el-icon-delete"></i> 删除
      </div>
      <div
        class="create-dir"
        @click="moveTo"
        v-if="
          addFiles1 &&
          this.$store.state.sidebarActive != 10 &&
          this.$parent.siderNum.name != '最近'
        "
      >
        <i class="el-icon-rank"></i> 移动
      </div>
      <div
        class="create-dir1 create-dir"
        @click="removeFile"
        v-if="addFiles2 && this.$store.state.sidebarActive == 10"
      >
        清空所有文件
      </div>
    </div>
    <!-- <div class="show-upload">
      <i class="show-upload-icon"></i>
    </div> -->
  </div>
</template>

<script>
import OfficeNav from "components/office-nav/OfficeNav";
export default {
  name: "DiskHeader",
  data() {
    return {
      addFiles: true,
      uploadFiles: true,
      addFiles1: false,
      addFiles2: false,
    };
  },
  methods: {
    deletsFiles() {
      this.$parent.deletsFiles();
    },
    moveTo() {
      this.$parent.moveTo();
    },
    removeFile() {
      this.$parent.removeFile();
    },
  },
  watch: {
    uploadFiles(newVal) {
      this.$emit("uploadFiles", newVal);
    },
    addFiles(newVal) {
      this.$emit("addFiles", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.disk-content-header {
  position: relative;
  @include flex-between();
  height: 42px;
  padding: 0 20px;
  border-bottom: 1px solid #eceff1;
  .file-operate {
    display: flex;
    margin-left: -20px;
    .upload-btn,
    .create-dir {
      @include flex-center;
      padding: 0 11px;
      font-size: 12px;
      height: 25px;
      font-weight: 500;
      line-height: 25px;
      border-radius: 12px;
      cursor: pointer;
    }
    .create-dir1 {
      background-color: #fff !important;
      position: absolute;
      right: 6px;
      top: 7px;
    }
    .upload-btn {
      background: $color-bg-heightlight;
      color: $color-text-white;
      .upload-btn-icon {
        margin-right: 5px;
        display: inline-block;
        width: 9px;
        height: 10px;
        @include backgroundGroup("~assets/images/disk/upload-btn-icon.png");
      }
    }
    .create-dir {
      color: $color-text-active;
      margin-left: 10px;
      background: #ebf2ff;
      .create-dir-icon {
        margin-right: 5px;
        display: inline-block;
        width: 8px;
        height: 9px;
        @include backgroundGroup("~assets/images/disk/create-dir-icon.png");
      }
      .share-dir-icon {
        margin-right: 5px;
        display: inline-block;
        width: 15px;
        height: 14px;
        @include backgroundGroup("~assets/images/disk/001.png");
      }
      .Download-dir-icon {
        margin-right: 5px;
        display: inline-block;
        width: 15px;
        height: 14px;
        @include backgroundGroup("~assets/images/disk/002.png");
      }
      .delete-dir-icon {
        margin-right: 5px;
        display: inline-block;
        width: 15px;
        height: 14px;
        @include backgroundGroup("~assets/images/disk/003.png");
      }
      .move-dir-icon {
        margin-right: 5px;
        display: inline-block;
        width: 15px;
        height: 14px;
        @include backgroundGroup("~assets/images/disk/004.png");
      }
    }
  }
  .show-upload {
    .show-upload-icon {
      display: inline-block;
      width: 17px;
      height: 15px;
      @include backgroundGroup("~assets/images/disk/show-upload-icon.png");
    }
  }
}
@media screen and (min-width: 1220px) {
  .disk-content-header {
    position: relative;
    @include flex-between();
    height: 53px;
    padding: 0 20px;
    border-bottom: 1px solid #eceff1;
    .file-operate {
      display: flex;
      .upload-btn,
      .create-dir {
        @include flex-center;
        padding: 0 17px;
        font-size: 14px;
        height: 32px;
        font-weight: 500;
        line-height: 32px;
        border-radius: 21px;
        cursor: pointer;
      }
      .create-dir1 {
        background-color: #fff !important;
        position: absolute;
        right: 6px;
        top: 7px;
      }
      .upload-btn {
        background: $color-bg-heightlight;
        color: $color-text-white;
        .upload-btn-icon {
          margin-right: 10px;
          display: inline-block;
          width: 12px;
          height: 13px;
          @include backgroundGroup("~assets/images/disk/upload-btn-icon.png");
        }
      }
      .create-dir {
        color: $color-text-active;
        margin-left: 20px;
        background: #ebf2ff;
        .create-dir-icon {
          margin-right: 8px;
          display: inline-block;
          width: 15px;
          height: 14px;
          @include backgroundGroup("~assets/images/disk/create-dir-icon.png");
        }
        .share-dir-icon {
          margin-right: 8px;
          display: inline-block;
          width: 16px;
          height: 16px;
          @include backgroundGroup("~assets/images/disk/001.png");
        }
        .Download-dir-icon {
          margin-right: 8px;
          display: inline-block;
          width: 16px;
          height: 16px;
          @include backgroundGroup("~assets/images/disk/002.png");
        }
        .delete-dir-icon {
          margin-right: 8px;
          display: inline-block;
          width: 16px;
          height: 16px;
          @include backgroundGroup("~assets/images/disk/003.png");
        }
        .move-dir-icon {
          margin-right: 8px;
          display: inline-block;
          width: 16px;
          height: 16px;
          @include backgroundGroup("~assets/images/disk/004.png");
        }
      }
    }
    .show-upload {
      .show-upload-icon {
        display: inline-block;
        width: 28px;
        height: 25px;
        @include backgroundGroup("~assets/images/disk/show-upload-icon.png");
      }
    }
  }
}
</style>
