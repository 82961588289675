<template>
  <!-- 上传器 -->
  <uploader
    ref="uploader"
    :options="options"
    :autoStart="false"
    :file-status-text="fileStatusText"
    @file-added="onFileAdded"
    @files-added="filesadded"
    @file-success="onFileSuccess"
    @file-error="onFileError"
    class="uploader-ui"
    :key="uploaderKey"
  >
    <uploader-unsupport></uploader-unsupport>
    <uploader-drop>
      <div>
        <uploader-btn
          id="global-uploader-btn"
          :key="uploadBtnKey"
          ref="uploadBtn"
          >选择文件<i class="el-icon-upload el-icon--right"></i
        ></uploader-btn>
      </div>
    </uploader-drop>
    <uploader-list>
      <el-table
        :data="tableData"
        v-show="tableData.length"
        @cell-click="cellClick"
      >
        <el-table-column label="文件名">
          <template slot-scope="{ row: { name } }">
            <div class="content" :title="name">
              <template>{{ name }}</template>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="大小">
          <template slot-scope="{ row }">{{
            row.size ? row.size : '-' | fileSizeFilter
          }}</template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <div class="operate">
              <span class="error" v-if="row.error">上传失败</span>
              <span class="el-icon-loading" v-if="row.isUploading()"></span>
              <span class="success" v-if="row.completed">上传成功</span>
              <span
                class="delete el-icon-close"
                @click="removeFile(row)"
              ></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </uploader-list>
  </uploader>
</template>

<script>
import { ACCEPT_CONFIG } from '@/config/upload'
import SparkMD5 from 'spark-md5'
import { mergeFile } from '@/api/uploadFile'
import { getToken } from '@/utils/auth'
export default {
  props: {
    parentId: {
      type: Number,
      required: true,
    },
    columnType: {
      type: Number,
      required: true,
    },
    fileType: {
      type: Number,
      required: true,
    },
  },
  filters: {
    fileSizeFilter: function (bytes) {
      if (bytes === '-') return '-'
      var k = 1024, // or 1024
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))

      return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
    },
  },
  mounted() {
    //  this.parentid=this.
  },
  data() {
    let TokenObj = getToken()
    let Authorization = ''
    if (TokenObj) {
      TokenObj = JSON.parse(TokenObj)
      Authorization = `${TokenObj.token_type} ${TokenObj.access_token}`
    } else {
      Authorization = process.env.VUE_APP_DEFAULT_TOKEN
    }
    return {
      idx1: 0,
      fileIndex: 0,
      // 选择上传文件
      tableData: [],
      uploaderKey: '',
      idx: 0,
      options: {
        //目标上传 URL，默认POST
        //target: "uploader/chunk",
        headers: { Authorization: Authorization },
        target: '/api/uploader/chunk',
        // target: process.env.VUE_APP_BASE_API+"api/uploader/chunk",
        //分块大小(单位：字节)
        chunkSize: '2048000',
        //上传文件时文件内容的参数名，对应chunk里的Multipart对象名，默认对象名为file
        fileParameterName: 'upfile',
        //失败后最多自动重试上传次数
        maxChunkRetries: 3,
        //是否开启服务器分片校验，对应GET类型同名的target URL
        testChunks: true,
        // query: {
        //   //携带参数
        //   id: this.parentId,
        //   price: this.money,
        //   columnType: this.columnType,
        // },
        query: (file) => {
          return {
            //携带参数
            id: this.parentId,
            price: this.money,
            columnType: this.columnType,
            author: this.$store.state.user_info.realName,
          }
        },
        /* 
        服务器分片校验函数，判断秒传及断点续传,传入的参数是Uploader.Chunk实例以及请求响应信息
        reponse码是successStatuses码时，才会进入该方法
        reponse码如果返回的是permanentErrors 中的状态码，不会进入该方法，直接进入onFileError函数 ，并显示上传失败
        reponse码是其他状态码，不会进入该方法，正常走标准上传
        checkChunkUploadedByResponse函数直接return true的话，不再调用上传接口
        */
        checkChunkUploadedByResponse: function (chunk, response_msg) {
          // console.log(response_msg)
          let objMessage = JSON.parse(response_msg)
          if (objMessage.status == '415') {
            return true
          }
          if (objMessage.skipUpload) {
            return true
          }
          return (
            (objMessage.uploadedChunks || []).indexOf(chunk.offset + 1) >= 0
          )
        },
      },
      attrs: {
        accept: ACCEPT_CONFIG.getAll(this.fileType),
      },

      fileStatusText: {
        success: '上传成功',
        error: '上传失败',
        uploading: '上传中',
        paused: '暂停',
        waiting: '等待上传',
      },
      uploadBtnKey: '',
    }
  },
  watch: {
    parentId: {
      handler: function (val) {
        this.parentId = val
      },
    },
    // tableData(val) {
    //   console.log(val)
    // },

    fileType() {
      this.$set(this.attrs, 'accept', ACCEPT_CONFIG.getAll(this.fileType))
      if (this.$refs['uploadBtn']) {
        this.uploadBtnKey = new Date().getTime()
      }
    },
  },
  created() {
    //清空文件列表
    this.$bus.$on('clearFileList', () => {
      this.uploaderKey = new Date().getTime()
    })
  },
  methods: {
    // table被点击
    cellClick(r, c, l, e) {
      e.stopPropagation()
      this.tableData.forEach((t) => {
        if (t.paused && t.id === r.id) {
          t.column.forEach((col) => {
            if (col.label == c.label) {
              this.tableData.forEach((t1) => {
                t1.column.forEach((c1) => (c1.edit = false))
              })
              col.edit = true
              col.cell = l
              if (col.label == '文件名') {
                col.value = l.innerText.replace(/\.(doc|docx)$/, '')
              } else {
                col.value = l.innerText
              }
              setTimeout(() => {
                this.$refs['textinput'].forEach((ti) => {
                  if (ti.className.indexOf('show') !== -1) {
                    ti.focus()
                  }
                })
              }, 300)
            }
          })
        }
      })
    },
    filesadded(files, fileList) {
      console.log(fileList)
      //单个文件的大小限制50M
      let fileSizeLimit = 50 * 1024 * 1024
      fileList.forEach((v) => {
        if (v.size > fileSizeLimit) {
          this.$message({
            showClose: true,
            message: '文件大小不能超过50M',
            duration: 1500,
          })
        } else {
          this.tableData.push(v)
        }
      })
      // if (file.size > fileSizeLimit) {
      //   this.$message({
      //     showClose: true,
      //     message: '文件大小不能超过50M',
      //     duration: 1500,
      //   })
      //   file.cancel()
      //   return
      // }
      // this.tableData.push(...fileList)
      if (this.tableData.length > 20) {
        this.$message.error('单次上传文件数最多20个')
        this.tableData = this.tableData.slice(0, 20)
      }
    },
    onFileAdded(file) {
      // if (this.idx1 < 30) {
      //   this.idx1++
      //   this.tableData.push(file)
      // } else {
      //   console.log(999)
      // }
    },
    //移除一个选择的文件
    removeFile(file) {
      this.tableData.forEach((f, fi) => {
        if (f.id === file.id) {
          this.tableData.splice(fi, 1)
          this.fileIndex = this.tableData.length
        }
      })
      if (!this.tableData.length) {
        this.$bus.$emit('clearFileList')
      }
    },
    /*
            第一个参数 rootFile 就是成功上传的文件所属的根 Uploader.File 对象，它应该包含或者等于成功上传文件；
            第二个参数 file 就是当前成功的 Uploader.File 对象本身；
            第三个参数就是 message 就是服务端响应内容，永远都是字符串；
            第四个参数 chunk 就是 Uploader.Chunk 实例，它就是该文件的最后一个块实例，如果你想得到请求响应码的话，chunk.xhr.status就是
            */
    onFileSuccess(rootFile, file, response, chunk) {
      this.idx++
      let objMessage = JSON.parse(response)
      if (objMessage.skipUpload) {
        if (this.idx == this.tableData.length) {
          this.$emit('onFileSuccess', 1)
        }

        //refProjectId为预留字段，可关联附件所属目标，例如所属档案，所属工程等
        file.refProjectId = '123456789'
        file.id = this.parentId
        // console.log(file)
        return false
      }
      file.columnType = this.columnType
      file.refProjectId = '123456789'
      file.id = this.parentId
      this.$store.commit('playLifeLoading', true)
      mergeFile(file)
        .then((responseData) => {
          this.$store.commit('playLifeLoading', false)
          if (this.idx == this.tableData.length) {
            this.$emit('onFileSuccess', 1)
          }
          if (responseData?.data.code === 415) {
            console.log('合并操作未成功，结果码：' + responseData.data.code)
          }
        })
        .catch(function (error) {
          console.log('合并后捕获的未知异常：' + error)
        })
    },
    onFileError(rootFile, file, response, chunk) {
      console.log('上传完成后异常信息：' + response)
      let objMessage = JSON.parse(response)
      //  this.error(`文件${file.name}上传失败，${objMessage.message}`);
      this.$emit('onFileErrors', 1)
      this.$message({
        type: 'error',
        message: `${file.name}上传失败，${objMessage.message}`,
        duration: 1500,
      })
    },

    /**
     * 计算md5，实现断点续传及秒传
     * @param file
     */
    computeMD5(file) {
      file.pause()
      //单个文件的大小限制50M
      let fileSizeLimit = 50 * 1024 * 1024
      if (file.size > fileSizeLimit) {
        this.$message({
          showClose: true,
          message: '文件大小不能超过50M',
          duration: 1500,
        })
        file.cancel()
        return
      }

      let fileReader = new FileReader()
      let time = new Date().getTime()
      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice
      let currentChunk = 0
      const chunkSize = 10 * 1024 * 1000
      let chunks = Math.ceil(file.size / chunkSize)
      let spark = new SparkMD5.ArrayBuffer()
      //由于计算整个文件的Md5太慢，因此采用只计算第1块文件的md5的方式
      let chunkNumberMD5 = 1

      loadNext()

      fileReader.onload = (e) => {
        spark.append(e.target.result)

        if (currentChunk < chunkNumberMD5) {
          loadNext()
        } else {
          let md5 = spark.end()
          file.uniqueIdentifier = md5
          file.resume()
          // console.log(
          //   `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
          //     file.size
          //   } 用时：${new Date().getTime() - time} ms`
          // );
        }
      }

      fileReader.onerror = function () {
        this.error(`文件${file.name}读取出错，请检查该文件`)
        file.cancel()
      }

      function loadNext() {
        let start = currentChunk * chunkSize
        let end = start + chunkSize >= file.size ? file.size : start + chunkSize

        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
        currentChunk++
        // console.log("计算第" + currentChunk + "块");
      }
    },
    close() {
      this.uploader.cancel()
    },
    error(msg) {
      this.$notify({
        title: '错误',
        message: msg,
        type: 'error',
        duration: 2000,
      })
      // this.$notify({
      //   title: "错误",
      //   message: msg,
      //   type: "error",
      //   duration: 2000
      // });
    },
  },

  destroyed() {
    this.$bus.$off('clearFileList')
  },
}
</script>

<style scoped>
.uploader-ui {
  margin: 30px auto 0;
  font-size: 12px;
  font-family: Microsoft YaHei;
}
.uploader-ui .uploader-btn {
  margin-right: 4px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
}
.uploader-ui .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.success {
  color: #08a008;
}
</style>
