<template>
  <div class="input_video">
    <video-player
      id="video"
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      @ended="onPlayerEnded($event)"
      :options="playerOptions"
    ></video-player>
  </div>
</template>
<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
export default {
  components: { videoPlayer },
  name: "BusImg",
  data() {
    return {
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          // {
          //   type: "video/mp4",
          //   src: require("../../assets/001video.mp4"), //url地址
          // },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },

  methods: {
    videoplayer() {
      this.$refs.videoPlayer.player.play();
    },
    onPlayerEnded($event) {
      this.$parent.transmits = true;
    },
  },
};
</script>
<style scoped>
/*播放按钮设置成宽高一致，圆形，居中*/
.vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 1.5em !important;
  line-height: 1.5em !important;
  /* margin-top: -1em !important;
  margin-left: -1em !important; */
  width: 1.5em !important;
  outline: none;
}

.video-js .vjs-big-play-button {
  position: absolute;
  left: calc(50% - 25px) !important;
  top: calc(50% - 25px) !important;
  width: 100%;
  height: 100%;
}
</style>
