<template>
  <div class="disk_table_row">
    <el-table
      v-if="this.$parent.showDalget"
      ref="multipleTable"
      :data="listDatas"
      tooltip-effect="dark"
      class="showDalget"
      id="el-table"
      @sort-change="comparing"
      @selection-change="handleSelectionChange"
      @row-contextmenu="handleContextmen"
      @row-dblclick="handleClicks"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="leaveRow"
    >
      <el-table-column
        type="selection"
        :selectable="selectable"
        width="50"
        v-if="this.$store.state.sidebarActive != 5"
      >
      </el-table-column>
      <el-table-column width="60">
        <template slot-scope="scope" show-overflow-tooltip>
          <el-image
            class="table-img"
            :src="require(`@/assets/images/disk/Icons/${scope.row.nub}.png`)"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="文件名" sortable="custom">
        <template slot-scope="scope" show-overflow-tooltip>
          <el-tooltip
            :disabled="scope.row.filename.length < 13 ? true : false"
            class="item"
            effect="light"
            :enterable="false"
            :content="scope.row.filename"
            placement="bottom"
          >
            <span>{{ scope.row.filename }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="updateTime"
        label="时间"
        width="170"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="fileSize"
        label="大小"
        width="80"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          scope.row.fileSize ? scope.row.fileSize : '-' | fileSizeFilter
        }}</template>
      </el-table-column>

      <div slot="append" class="pagination">
        <el-pagination
          small=""
          :page-size="10"
          background
          layout="prev, pager, next"
          :total="Total"
          @current-change="sizeChange"
        >
        </el-pagination>
      </div>
    </el-table>

    <div class="rightClickNav" v-if="isshow" id="contextmenu">
      <div
        class="rightClickNav-row"
        @click="openFile"
        v-if="this.$store.state.sidebarActive != 10 && pdfshow"
      >
        <img
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/001.png"
          alt=""
        />
        打开
      </div>
      <div
        class="rightClickNav-row"
        @click="downloadFied"
        v-if="this.$store.state.sidebarActive != 10 && downloadFied1"
      >
        <img
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/002.png"
          alt=""
        />
        下载
      </div>
      <!-- <div
        class="rightClickNav-row"
        @click="handlechange"
        v-if="this.$store.state.sidebarActive != 10"
      >
        <img
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/003.png"
          alt=""
        />
        分享
      </div> -->
      <div
        class="rightClickNav-row"
        :class="{ color: this.$store.state.sidebarActive != 10 ? false : true }"
        v-if="handledels && this.$parent.siderNum.name != '最近'"
        @click="handledel"
      >
        <img
          v-if="this.$store.state.sidebarActive != 10"
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/007.png"
          alt=""
        />
        <span
          v-else
          style="width: 16px; height: 16px; margin-right: 8px"
          class="filehui"
        ></span>
        <!-- <img
          v-else
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/009.png"
          alt=""
        /> -->
        删除
      </div>
      <div
        class="rightClickNav-row"
        @click="handlechange"
        v-if="this.$store.state.sidebarActive != 10 && handledels"
      >
        <img
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/005.png"
          alt=""
        />
        重命名
      </div>
      <div
        class="rightClickNav-row"
        @click="moveTo"
        v-if="
          this.$store.state.sidebarActive != 10 &&
          handledels &&
          this.$parent.siderNum.name != '最近'
        "
      >
        <img
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/004.png"
          alt=""
        />
        移动到
      </div>
      <div
        class="rightClickNav-row"
        @click="information"
        v-if="this.$store.state.sidebarActive != 10"
      >
        <img
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/006.png"
          alt=""
        />
        详细信息
      </div>
      <div
        class="rightClickNav-row"
        @click="openFile(1)"
        v-if="this.$store.state.sidebarActive != 10 && isPars"
      >
        <img
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/001.png"
          alt=""
        />
        解析文档
      </div>
      <div
        class="rightClickNav-row color"
        @click="handlereduction"
        v-if="this.$store.state.sidebarActive == 10"
      >
        <span
          style="width: 16px; height: 16px; margin-right: 8px"
          class="filehead"
        ></span>
        <!-- <img
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/008.png"
          alt=""
        /> -->
        还原文件
      </div>
      <div
        class="rightClickNav-row color"
        @click="handleempty"
        v-if="this.$store.state.sidebarActive == 10"
      >
        <span
          style="width: 16px; height: 16px; margin-right: 8px"
          class="filehui"
        ></span>
        <!-- <img
          style="width: 16px; height: 16px; margin-right: 8px"
          src="@/assets/images/disk/Icons/009.png"
          alt=""
        /> -->
        清空回收站
      </div>
    </div>
    <!-- 详细信息 -->
    <el-dialog :title="infotion" :visible.sync="infoFilesPopup" class="addFil">
      <div>
        <el-image
          class="images"
          :src="require(`@/assets/images/disk/Icons/${nub}.png`)"
        ></el-image
        >&nbsp;&nbsp;&nbsp; {{ textCenter }}
        <!-- <span class="spans" v-text="textCenter"></span> -->
      </div>
      <div class="samll"></div>
      <div class="box">
        <span class="boxSpan">类型：</span>&nbsp;&nbsp;&nbsp;
        <span v-text="textType"></span>
      </div>
      <div class="box">
        <span class="boxSpan">位置：</span>&nbsp;&nbsp;&nbsp;
        <span class="position" v-text="position"></span>
      </div>
      <div class="box">
        <span class="boxSpan">大小：</span>&nbsp;&nbsp;&nbsp;
        <span v-text="size"></span>
      </div>
      <div class="box">
        <span class="boxSpan">修改时间：</span>&nbsp;&nbsp;&nbsp;
        <span v-text="modifyTime"></span>
      </div>
    </el-dialog>
    <!-- 移动到 -->
    <el-dialog :title="moveTitle" :visible.sync="movePopup" class="addFil">
      <div class="container">
        <el-tree
          :expand-on-click-node="false"
          style="180px"
          :data="TreeData"
          accordion
          :auto-expand-parent="false"
          node-key="id"
          :props="defaultProps"
          @node-click="handleNodeClick"
          :default-expanded-keys="treeExpandData"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>
              {{ test(node, data) }}
              <el-image
                :src="require(`@/assets/images/disk/Icons/${data.picture}.png`)"
                style="width: 30px; height: 30px; vertical-align: middle"
              ></el-image>
              {{ data.filename }}
            </span>
          </span>
        </el-tree>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSure">确 定</el-button>
        <el-button @click="movePopup = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="showRealNameTips"
      :modal="false"
      :close-on-click-modal="false"
      title="提示"
      class="real-name-dialog"
    >
      <div class="content">{{ content }}</div>
      <div class="footer">
        <span class="now" @click="realNameDialog">确定</span>
        <span class="next" @click="showRealNameTips = false">取消</span>
      </div>
    </el-dialog>
    <div class="switch" v-if="!this.$parent.showDalget">
      <div
        class="fooder-hd"
        @click="mouseclick(item)"
        @contextmenu.prevent="rightClick(item, $event)"
        v-for="(item, index) in listData"
        :key="index"
      >
        <!-- <div  class="box-fooder">暂无数据</div> -->
        <el-image
          class="banner-img"
          :src="require(`@/assets/images/disk/Icons/${item.nub}.png`)"
        ></el-image>
        <el-popover
          v-if="item.filename.split('').length > 11"
          placement="bottom"
          width="150"
          trigger="hover"
          :content="item.filename"
        >
          <div class="fooder-bd" slot="reference">{{ item.filename }}</div>
        </el-popover>
        <div class="fooder-bd" v-else>{{ item.filename }}</div>
      </div>
    </div>
    <!-- 查看图片视频 -->
    <el-dialog
      title="预览"
      :visible.sync="lookImg"
      class="addFolder"
      @close="lookImg = false"
    >
      <img v-if="imgShow" :src="imgUrl" alt="" />
      <div v-if="!imgShow"><videoPlayerPage ref="videoPlayer" /></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  delFiles,
  recycleBinDel,
  getRecycleBinList,
  recycleBinReduction,
  recycleBinEmpty,
  changeFileDetail,
  directory,
  previewFile,
  moveFile,
} from 'api/disk'
import videoPlayerPage from './DiskVideo.vue'
export default {
  components: {
    videoPlayerPage,
  },
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: () => 0,
    },
  },
  filters: {
    fileSizeFilter: function (bytes) {
      if (bytes === '-') return '-'
      var k = 1024, // or 1024
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))

      return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
    },
  },
  name: 'DiskTableRow',
  created() {},
  watch: {
    listData(val) {
      this.listDatas = val
    },
    total(val) {
      console.log(val)
      this.Total = val
    },
  },
  data() {
    return {
      // 分页
      Total: 0,
      listDatas: [],
      // 是否点击的解析文档
      analysis: false,
      // 解析文档按钮判断
      isPars: true,
      // 视频图片判断
      imgShow: true,
      // 图片路径
      imgUrl: '',
      // 查看图片弹框
      lookImg: false,
      pdfshow: true,
      opertData: [],
      showRightOp: false,
      treeExpandData: [],
      content: '',
      showRealNameTips: false,
      handledels: true,
      visibleData: false,
      downloadFied1: true,
      imageRequest: false,
      displayData: true,
      displayDatas: true,
      str: 1,
      rowId: '',
      nub: '01',
      nubs: 1,
      nubs1: 1,
      moveid: '',
      Picturepreview: '',
      previewFile: false,
      textCenter: '',
      textType: '',
      position: '',
      size: '',
      modifyTime: '',
      isSelected: false,
      isshow: false,
      // 定时器
      timer: null,
      rowItem: '', //右键点击行 信息
      centenail: '',
      infotion: '详细信息',
      moveTitle: '移动到',
      sttry: 1,
      infoFilesPopup: false,
      movePopup: false,
      showRightId: null,
      TreeData: [],
      checkData: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  mounted() {
    // window.addEventListener('scroll', this.scrollHandle, true)
  },
  destroyed() {
    // window.removeEventListener('scroll', this.scrollHandle, true)
  },
  methods: {
    //  解析状态
    format(val) {
      return val == 100 ? '解析成功' : val == 0 ? '0%' : val + '%'
    },
    // 分页
    sizeChange(i) {
      console.log(i)
      if (this.$parent.type == 0) {
        this.$parent.getDiskList({
          id: this.$parent.MyItem[this.$parent.MyItem.length - 1].id,
          pageIndex: i,
          pageSize: 10,
          sizeSort: '',
          timeSort: 'desc',
        })
      } else if (this.$parent.type == 1) {
        this.$parent.getsideBarTypes({
          pageIndex: i,
        })
      }
    },
    scrollHandle(event) {
      let el = event.target
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        if (this.$parent.stry == 10) {
          this.visibleData = true
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            if (this.$parent.type == 0) {
              this.nubs = this.nubs + 1
              this.$parent.getDiskList({
                id: this.$parent.MyItem[this.$parent.MyItem.length - 1].id,
                pageIndex: this.nubs,
                pageSize: 10,
                sizeSort: '',
                timeSort: 'desc',
              })
              setTimeout(() => {
                this.visibleData = false
              }, 4000)
            } else if (this.$parent.type == 1) {
              this.nubs1 = this.nubs1 + 1
              this.$parent.getsideBarTypes({
                pageIndex: this.nubs1,
              })
              setTimeout(() => {
                this.visibleData = false
              }, 4000)
            }
          }, 2000)
        }
        //加载列表
      }
    },
    // 左击
    mouseclick(val) {
      this.handleClicks(val)
    },
    // 右击
    rightClick(row, event) {
      this.rowItem = row
      let arr = row.filename.split('.')
      if (arr.length > 1) {
        this.downloadFied1 = true

        if (
          row.filename.split('.')[row.filename.split('.').length - 1] == 'pdf'
        ) {
          this.pdfshow = false
        } else {
          this.pdfshow = true
        }
      } else {
        this.downloadFied1 = false
      }
      if (row.isDeleted == -1) {
        this.handledels = false
      } else {
        this.handledels = true
      }
      // if(row.isParse)

      this.centenail = {
        id: row.id,
        filename: row.filename,
        url: row.url,
        filetype: row.filetype,
        parseUrl: row.parseUrl,
      }
      this.isshow = false
      this.isshow = true
      this.$nextTick(() => {
        this.init(row, '', event)
      })
    },
    selectable(row, index) {
      if (row.isDeleted == -1) {
        return false
      } else {
        return true
      }
    },
    // hover效果下载
    downloadFied2() {
      window.event.cancelBubble = true
      this.$parent.downloadFied(this.showRightId.id, this.showRightId.filename)
    },
    // hover效果更多
    moreClick(row, event) {
      window.event.cancelBubble = true

      this.rowItem = row
      let arr = row.filename.split('.')
      if (arr.length > 1) {
        this.downloadFied1 = true
      } else {
        this.downloadFied1 = false
      }
      if (row.isDeleted == -1) {
        this.handledels = false
      } else {
        this.handledels = true
      }

      this.centenail = {
        id: row.id,
        filename: row.filename,
        url: row.url,
        filetype: row.filetype,
        parseUrl: row.parseUrl,
      }
      this.isshow = false
      this.isshow = true
      this.$nextTick(() => {
        this.init(row, '', event)
      })
    },
    // hover效果删除
    // deleteFile() {
    //   window.event.cancelBubble = true;
    //   this.rowItem = this.showRightId;
    //   this.handledel();
    // },
    // 鼠标移入
    cellMouseEnter(row, column, cell, event) {
      this.showRightOp = true
      this.rowId = row.id
      this.showRightId = row
      let arr = row.filename.split('.')
      if (arr.length > 1) {
        if (this.$store.state.sidebarActive == 10) {
          this.displayDatas = false
        } else {
          this.displayDatas = true
        }
        this.displayData = true
      } else {
        this.displayData = false
      }
    },
    // 鼠标移出
    leaveRow(row, column, cell, event) {
      this.showRightOp = false
      this.rowId = ''
    },
    test(node, data) {
      switch (data.filetype) {
        case 0:
          if (data.isDeleted == -1) {
            data.picture = '01'
          } else {
            data.picture = '03'
          }
          break
      }
    },
    // 下载
    downloadFied() {
      this.$parent.downloadFied(
        this.rowItem.id,
        this.rowItem.filename,
        this.rowItem.url
      )
    },
    handleNodeClick(data) {
      this.moveid = data.id
      this.$parent.moveid = data.id
    },
    // 移动到
    async moveTo(checkData) {
      this.checkData = checkData
      this.movePopup = true
      const data = {
        id: 0,
      }
      const res = await directory(data)
      this.TreeData = res.data
      this.treeExpandData.push(res.data[0].id)
    },
    // 移动确定
    async handleSure() {
      const res = []
      let atr = {}
      if (this.rowItem.id) {
        atr = {
          id: this.rowItem.id,
          moveid: this.moveid,
        }
        res.push(atr)
      } else {
        this.checkData.forEach((item) => {
          atr = {
            id: item.id,
            moveid: this.moveid,
          }
          res.push(atr)
        })
      }

      let data = await moveFile(res)
      if (data.code == 200) {
        this.$message({
          type: 'success',
          message: '移动成功！',
          duration: 1000,
        })
        this.movePopup = false
        if (this.$parent.siderNum.id == 0) {
          this.$parent.getDiskLists({
            id: this.$parent.MyItem[this.$parent.MyItem.length - 1].id,
            pageIndex: 1,
            pageSize: 20,
            sizeSort: '',
            timeSort: 'desc',
          })
        } else {
          this.$parent.getsideBarType({
            name: this.$parent.siderNum.name,
            pageIndex: 1,
            pageSize: 20,
          })
        }
      } else {
        this.$message({
          type: 'error',
          message: data.message,
          duration: 1000,
        })
      }
    },
    // 详细信息
    async information() {
      this.infotion = '详细信息'
      this.infoFilesPopup = true
      const data = {
        id: this.rowItem.id,
      }

      const res = await changeFileDetail(data)
      if (res.code == 200) {
        this.textCenter = res.data.name
        this.textType = res.data.type
        this.position = res.data.position
        this.size = res.data.size
        this.modifyTime = res.data.updateTime
        switch (res.data.filetype) {
          case 0:
            // if (item.isDeleted == -1) {
            //   item.nub = "01";
            // } else {
            //   item.nub = "03";
            // }
            this.nub = '01'
            break
          case 1:
            this.nub = '04'
            break
          case 2:
            this.nub = '07'
            break
          case 3:
            this.nub = '08'
            break
          case 4:
            this.nub = '05'
            break
          case 5:
            this.nub = '06'
            break
          case 6:
            this.nub = '07'
            break
          case 7:
            this.nub = '04'
            break
          case 8:
            this.nub = '05'
            break
          case 9:
            this.nub = '14'
            break
          case 10:
            this.nub = '14'
            break
          case 11:
            this.nub = '09'
            break
          case 12:
            this.nub = '09'
            break
          case 13:
            this.nub = '09'
            break
          case 14:
            this.nub = '10'
            break
          case 15:
            this.nub = '01'
            break
          case 16:
            this.nub = '09'
            break
          case 17:
            this.nub = '12'
            break
          case 18:
            this.nub = '12'
            break
          case 19:
            this.nub = '13'
            break
          case 20:
            this.nub = '11'
            break
          case 21:
            this.nub = '15'
            break
          case 22:
            this.nub = '11'
            break
          case 23:
            this.nub = '15'
            break
        }
      }
    },
    // 排序
    comparing({ column, prop, order }) {
      this.nubs = 1
      this.nubs1 = 1
      let timeSort = ''
      let sizeSort = ''
      if (prop == 'updateTime') {
        if (order == 'descending') {
          timeSort = 'desc'
        } else if (order == 'ascending') {
          timeSort = 'asc'
        } else {
          timeSort = ''
        }
      } else if (column.label == '文件名') {
        timeSort = ''
        sizeSort = ''
      } else {
        if (order == 'descending') {
          sizeSort = 'desc'
        } else if (order == 'ascending') {
          sizeSort = 'asc'
        } else {
          sizeSort = ''
        }
      }
      if (this.$parent.siderNum.id == 0) {
        this.$parent.getDiskLists({
          id: this.$parent.MyItem[this.$parent.MyItem.length - 1].id,
          pageIndex: 1,
          pageSize: 10,
          sizeSort: sizeSort,
          timeSort: timeSort,
        })
      } else {
        this.$parent.getsideBarType({
          name: this.$parent.siderNum.name,
          pageIndex: 1,
          pageSize: 10,
          sizeSort: sizeSort,
          timeSort: timeSort,
        })
      }
    },
    // 打开
    openFile(id) {
      if (id == 1) {
        this.analysis = true
      } else {
        this.analysis = false
      }
      this.$nextTick(() => {
        this.handleClicks(this.centenail)
      })
    },
    // 鼠标点击触发
    async handleClicks(row, column, event) {
      console.log(row)
      let fileSizeLimit = 10 * 1024 * 1024
      if (row.fileSize > fileSizeLimit) {
        this.$message({
          showClose: true,
          message: '打开文件大小不能超过10M',
          duration: 1500,
        })
        return
      }
      if (row.filename.split('.').length > 1) {
        const data = {
          id: row.id,
        }
        previewFile(data).then((res) => {
          if (res.code == 200) {
            window.open(res.data)
          }
        })
      } else {
        if (this.str == 1) {
          this.str = 2
          let str1 = { id: row.id, name: row.filename }
          this.$parent.MyItem.push(str1)
          this.$parent.getDiskLists({
            id: row.id,
            pageIndex: 1,
            pageSize: 11,
            timeSort: 'desc',
          })
          setTimeout(() => {
            this.str = 1
          }, 800)
        }
      }
      this.$parent.beyondHiding()
    },
    // async handleClicks(row) {
    //   if (row.fileSize > 10000000) {
    //     this.$parent.downloadFied(row.filename, row.url)
    //   } else {
    //     if (row.filename.split('.').length > 1) {
    //       if (this.analysis) {
    //         try {
    //           // eslint-disable-next-line
    //           OpenDocument2(
    //             row.filename,
    //             this.$store.state.onlineBasePath + row.parseUrl
    //           )
    //         } catch (e) {
    //           console.log(e)
    //         }
    //         return
    //       }
    //       if (
    //         row.filetype == 11 ||
    //         row.filetype == 12 ||
    //         row.filetype == 13 ||
    //         row.filetype == 16 ||
    //         // row.filetype == 18 ||
    //         row.filetype == 22
    //       ) {
    //         this.lookImg = true
    //         if (row.filetype == 22) {
    //           this.imgShow = false
    //           this.$nextTick(() => {
    //             this.$refs['videoPlayer'].playerOptions.sources.push({
    //               type: 'video/mp4',
    //               src: this.$store.state.onlineBasePath + row.url,
    //             })
    //           })
    //         } else {
    //           this.imgShow = true
    //           this.imgUrl = this.$store.state.onlineBasePath + row.url
    //         }
    //       } else {
    //         try {
    //           // eslint-disable-next-line
    //           OpenDocument2(
    //             row.filename,
    //             this.$store.state.onlineBasePath + row.url
    //           )
    //         } catch (e) {
    //           console.log(e)
    //         }
    //       }

    //       // let data={
    //       //   id: row.id,
    //       // }
    //       // previewFile(data).then((res) => {
    //       //   if (res.code == 200) {
    //       //     window.open(res.data)
    //       //   }
    //       // })
    //     } else {
    //       if (this.str == 1) {
    //         this.str = 2
    //         let str1 = { id: row.id, name: row.filename }
    //         // if (str1.name == '网页采集文档' || str1.name == '数据分析报告') {
    //         //   this.$parent.MyItem = [
    //         //     { id: '0', name: '我的网盘' },
    //         //     ...this.$parent.MyItem,
    //         //     str1,
    //         //   ]
    //         // } else {
    //         this.$parent.MyItem = [...this.$parent.MyItem, str1]
    //         // }

    //         this.$parent.getDiskLists({
    //           id: row.id,
    //           pageIndex: 1,
    //           pageSize: 20,
    //           sizeSort: '',
    //           timeSort: 'desc',
    //         })
    //         setTimeout(() => {
    //           this.str = 1
    //         }, 800)
    //       }
    //     }
    //     this.$parent.beyondHiding()
    //   }
    // },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    // 选择项发生变化时
    handleSelectionChange(val) {
      this.multipleSelection = val
      let arr = []
      val.forEach((item) => {
        let str = {
          id: item.id,
        }
        arr.push(str)
      })
      this.opertData = arr
      this.$parent.checkData = arr
      if (val.length > 0) {
        this.$parent.handleSelection(1)
      } else {
        this.$parent.handleSelection(2)
      }
    },
    handleContextmen(row, column, event) {
      console.log(row, column, event)
      let arr = row.filename.split('.')
      if (arr.length > 1) {
        this.downloadFied1 = true
      } else {
        this.downloadFied1 = false
      }
      if (row.isDeleted == -1) {
        this.handledels = false
      } else {
        this.handledels = true
      }
      if (row.isParse == 2 && row.filetype != 2 && row.filetype != 6) {
        this.isPars = true
      } else {
        this.isPars = false
      }
      this.centenail = {
        id: row.id,
        filename: row.filename,
        url: row.url,
        filetype: row.filetype,
        parseUrl: row.parseUrl,
      }

      this.isshow = false
      this.isshow = true
      event.preventDefault()
      this.rowItem = row
      this.$nextTick(() => {
        this.init(row, column, event)
      })
    },
    init(row, column, event) {
      // 设置菜单出现的位置
      // 具体显示位置根据自己需求进行调节
      let menu = document.querySelector('#contextmenu')
      let cha = document.body.clientHeight - event.clientY
      let kua = document.body.clientWidth - event.clientX
      // 防止菜单太靠底，根据可视高度调整菜单出现位置
      if (cha < 150) {
        menu.style.top = event.clientY - 120 + 'px'
      } else {
        menu.style.top = event.clientY - 80 + 'px'
        // menu.style.top = event.clientY - 10 + 'px'
      }
      if (kua < 150) {
        menu.style.left = event.clientX - 120 + 'px'
      } else {
        menu.style.left = event.clientX - 220 + 'px'
        // menu.style.left = event.clientX - 10 + 'px'
      }

      document.addEventListener('click', this.foo) // 给整个document添加监听鼠标事件，点击任何位置执行foo方法
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.isshow = false
      document.removeEventListener('click', this.foo)
    },
    // 删除
    async handledel() {
      if (this.$store.state.sidebarActive == 10) {
        this.content = '确定删除选中的文件吗?'
        this.showRealNameTips = true
        this.sttry = 1
      } else {
        this.content = '确定删除选中的文件吗?'
        this.showRealNameTips = true
        this.sttry = 3
      }
    },
    // 修改名称
    handlechange() {
      this.$parent.addtitle = '修改名称'
      this.$parent.addFilesPopup = true
      this.$parent.form.name = this.rowItem.filename
    },
    // 获取回收站列表
    async getRecyCleList() {
      this.$parent.getSideBarType({
        name: '回收站',
        pageIndex: 1,
        pageSize: 20,
      })
    },

    // 还原文件
    handlereduction() {
      this.content = '确定还原选中的文件吗?'
      this.showRealNameTips = true
      this.sttry = 2
    },
    // 确定
    async realNameDialog() {
      this.showRealNameTips = false
      if (this.sttry == 1) {
        // 回收站删除
        let data1 = []
        let str = {}

        if (this.opertData.length > 0) {
          this.opertData.forEach((item) => {
            str = {
              id: item.id,
            }
            data1.push(str)
          })
        } else {
          str = {
            id: this.rowItem.id,
          }
          data1.push(str)
        }
        const res = await recycleBinDel(data1)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '已删除！',
            duration: 1000,
          })
          this.getRecyCleList()
        } else {
          this.$message({
            type: 'error',
            message: res.message,
            duration: 1000,
          })
        }
      } else if (this.sttry == 2) {
        let data1 = []
        let str = {}

        if (this.opertData.length > 0) {
          this.opertData.forEach((item) => {
            str = {
              id: item.id,
            }
            data1.push(str)
          })
        } else {
          str = {
            id: this.rowItem.id,
          }
          data1.push(str)
        }

        const res = await recycleBinReduction(data1)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '还原成功！',
            duration: 1000,
          })
          this.getRecyCleList()
        } else {
          this.$message({
            type: 'error',
            message: res.message,
            duration: 1000,
          })
        }
      } else if (this.sttry == 0) {
        const res = await recycleBinEmpty()
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '已清空！',
            duration: 1000,
          })
          this.getRecyCleList()
        } else {
          this.$message({
            type: 'error',
            message: res.message,
            duration: 1000,
          })
        }
      } else {
        // 普通删除
        if (this.rowItem.isDeleted == -1) {
          this.$message({
            type: 'error',
            message: '该文件夹不能删除！',
            duration: 1000,
          })
          return
        }
        const data = [
          {
            id: this.rowItem.id,
          },
        ]
        const res = await delFiles(data)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '已删除到回收站！',
            duration: 1000,
          })
          if (this.$parent.siderNum.id == 0) {
            this.$parent.getDiskLists({
              id: this.$parent.MyItem[this.$parent.MyItem.length - 1].id,
              pageIndex: 1,
              pageSize: 20,
              sizeSort: '',
              timeSort: 'desc',
            })
          } else {
            this.$parent.getsideBarType({
              name: this.$parent.siderNum.name,
              pageIndex: 1,
              pageSize: 20,
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: res.message,
            duration: 1000,
          })
        }
      }
      this.$parent.getMemoryFile()
    },
    // 清空回收站
    async handleempty() {
      this.content = '确定要清空回收站吗?'
      this.showRealNameTips = true
      this.sttry = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.disk_table_row {
  margin: 0 20px;
  // width: calc(100% - 42px);
  border: 1px solid #eceff1;
  border-top: none;
  border-bottom: none;
}
.samll {
  margin-top: 2px;
  height: 1px;
  background-color: #f0f0f0;
}
.images {
  width: 27px;
  height: 27px;
  vertical-align: middle;
}
.spans {
  font-size: 12px;
  display: inline-block;
}
.rightClickNav {
  z-index: 9999;
  position: absolute;
  width: 128px;
  background: #fff;
  display: inline-block;
  min-width: 8em;
  max-width: 26em;
  padding: 0.25em 0;
  margin: 0.3em;
  font-family: inherit;
  font-size: inherit;
  list-style-type: none;
  background: #fff;
  border: 1px solid #fafbfb;
  border-radius: 0.2em;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  &-row {
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    .filehead {
      background: url('~assets/images/disk/Icons/008.png') no-repeat;
      background-size: 100% 100%;
    }
    .filehui {
      background: url('~assets/images/disk/Icons/009.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  &-row:hover {
    background: #4587ff;
    color: #fff;
    cursor: pointer;
    .filehead {
      background: url('~assets/images/disk/Icons/huaiyuan.png') no-repeat;
      background-size: 100% 100%;
    }
    .filehui {
      background: url('~assets/images/disk/Icons/shangchu.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}
.addFil {
  ::v-deep .el-dialog {
    width: 54%;
    .el-dialog__header {
      padding: 10px 20px 0px;
    }
    .el-dialog__title {
      font-size: 14px;
    }
    .el-dialog__body {
      padding: 8px 12px 15px;
    }
  }
}

.box {
  margin-top: 16px;
  margin-left: 7px;
  font-size: 12px;
  color: #333;
}
.position {
  line-height: 20px;
}
.boxSpan {
  color: #a4a4a4;
}
#dialog {
  margin-top: -30px;
}
.table-img {
  width: 28px;
  height: 28px;
}
.container {
  overflow: auto;
  width: 100%;
  max-height: 150px;
  min-height: 40px;
  font-size: 12px;
}
.switch {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
  max-height: 440px;
  min-height: 150px;
}
.real-name-dialog {
  ::v-deep .el-dialog {
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    margin-top: 20vh !important;
    width: 340px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 10px 10px;
      .el-dialog__title {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 11px;
      }
    }
    .el-dialog__body {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      flex: 1;
      padding: 20px 61px 35px 59px;
      .content {
        margin-top: 8px;
        @include flex-center;
        align-items: normal;
        flex: 1;
        font-size: 16px;
        color: #333333;
      }
      .footer {
        @include flex-between;
        @include noselect;
        > span {
          padding: 0 8px;
          font-size: 14px;
          font-weight: 500;
          color: #4587ff;
          cursor: pointer;
        }
      }
    }
  }
}
/deep/ #el-table {
  .el-table__cell {
    padding: 2px 0;
    font-size: $font-size-small;
  }
  .el-table__row {
    height: 50px;
    .cell {
      .el-tooltip {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.header-hd {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.fooder-hd {
  cursor: pointer;
  text-align: center;
  width: 80px;
  height: 80px;
  margin: 12px;
}
.fooder-bd {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  margin-top: 8px;
}
.showDalget {
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 250px);
  min-height: 440px;
  .pagination {
    margin: 10px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    ::v-deep .active {
      background-color: #ff6900 !important;
    }
  }
  .hover_box {
    // display: none;
    text-align: center;

    .vip {
      height: 30px;
      width: 70px;
      // padding: 0 5px;
      line-height: 30px;
      font-size: 12px;
      // padding: 5px;
      border-radius: 5px;
      color: #fff;
    }
  }
  .green {
    background-color: #67c23a;
  }
  .red {
    background-color: #f56c6c;
  }
  .blue {
    background-color: #66b1ff;
  }
  .yellow {
    background-color: #a6a9ad;
  }
}
::v-deep .el-table__row:hover {
  .hover_box {
    right: 40px;
    top: calc(50% - 11px);
    // box-sizing: border-box;
    justify-content: space-between;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.color {
  color: #4587ff;
}
.box-fooder {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  color: #909399;
}
.el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px !important;
}
.dialog-footer {
  ::v-deep .el-button {
    padding: 8px 16px;
    font-size: 12px;
    border-radius: 3px;
  }
}
.banner-img {
  width: 56px;
  height: 56px;
}
@media screen and(min-width: 1220px) {
  .table-img {
    width: 40px;
    height: 40px;
  }
  .banner-img {
    width: 80px;
    height: 80px;
  }
  .samll {
    margin-top: 2px;
    height: 1px;
    background-color: #f0f0f0;
  }
  .images {
    width: 27px;
    height: 27px;
    vertical-align: middle;
  }
  .spans {
    font-size: 14px;
    display: inline-block;
  }
  .rightClickNav {
    z-index: 9999;
    position: absolute;
    width: 128px;
    background: #fff;
    display: inline-block;
    min-width: 8em;
    max-width: 26em;
    padding: 0.25em 0;
    margin: 0.3em;
    font-family: inherit;
    font-size: inherit;
    list-style-type: none;
    background: #fff;
    border: 1px solid #fafbfb;
    border-radius: 0.2em;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    &-row {
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    &-row:hover {
      background: #4587ff;
      color: #fff;
      cursor: pointer;
    }
  }
  .addFil {
    ::v-deep .el-dialog {
      width: 54%;
      .el-dialog__header {
        padding: 10px 20px 0px;
      }
      .el-dialog__title {
        font-size: 14px;
      }
      .el-dialog__body {
        padding: 8px 12px 15px;
      }
    }
  }

  .box {
    margin-top: 16px;
    margin-left: 7px;
    font-size: 14px;
    color: #333;
  }
  .position {
    line-height: 20px;
  }
  .boxSpan {
    color: #a4a4a4;
  }
  #dialog {
    margin-top: -30px;
  }
  .container {
    overflow: auto;
    width: 100%;
    max-height: 150px;
    min-height: 46px;
    font-size: 14px;
  }
  .switch {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: auto;
    max-height: calc(100vh - 200px);
    min-height: 150px;
  }
  .real-name-dialog {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      margin-top: 20vh !important;
      width: 340px;
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 10px 10px;
        .el-dialog__title {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 11px;
        }
      }
      .el-dialog__body {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
        flex: 1;
        padding: 20px 61px 35px 59px;
        .content {
          margin-top: 8px;
          @include flex-center;
          align-items: normal;
          flex: 1;
          font-size: 16px;
          color: #333333;
        }
        .footer {
          @include flex-between;
          @include noselect;
          > span {
            padding: 0 8px;
            font-size: 14px;
            font-weight: 500;
            color: #4587ff;
            cursor: pointer;
          }
        }
      }
    }
  }
  /deep/ #el-table {
    .el-table__cell {
      padding: 12px 0;
      font-size: 14px;
    }
    .el-table__row {
      height: 50px;
      .cell {
        .el-tooltip {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .header-hd {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  .fooder-hd {
    cursor: pointer;
    text-align: center;
    width: 130px;
    height: 130px;
    margin: 20px;
  }
  .fooder-bd {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    margin-top: 8px;
  }
  .showDalget {
    overflow: auto;
    width: 100%;
    height: calc(100vh - 250px);
  }
  .color {
    color: #4587ff;
  }
  .box-fooder {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: #909399;
  }
  .el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px !important;
  }
  .dialog-footer {
    ::v-deep .el-button {
      padding: 8px 16px;
      font-size: 14px;
      border-radius: 3px;
    }
  }
}
.addFolder {
  img {
    width: 100%;
    height: auto;
  }
}
::v-deep .progress {
  width: 40px;
  height: 40px;
  margin-top: 5px;

  .el-progress-circle {
    width: 40px !important;
    height: 40px !important;
  }
  .el-progress__text {
    font-size: 12px !important;
  }
}
</style>
<style>
.el-popover {
  font-size: 12px;
}
</style>
