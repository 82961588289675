export const sidebarItem = [
  {
    name: "全部文件",
    id: 0,
    children: [
      {
        nIcon: require("assets/images/disk/Icons/01.png"),
        hIcon: require("assets/images/disk/Icons/01.png"),
        name: "收藏文档",
        id: 11,
      },
      // {
      //   nIcon: require("assets/images/disk/Icons/01.png"),
      //   hIcon: require("assets/images/disk/Icons/01.png"),
      //   name: "浏览记录",
      //   id: 9,
      // },
      {
        nIcon: require("assets/images/disk/Icons/01.png"),
        hIcon: require("assets/images/disk/Icons/01.png"),
        name: "一键采集",
        id: 20,
      },
      {
        nIcon: require("assets/images/disk/Icons/01.png"),
        hIcon: require("assets/images/disk/Icons/01.png"),
        name: "云文档",
        id: 6,
      },
      {
        nIcon: require("assets/images/disk/Icons/01.png"),
        hIcon: require("assets/images/disk/Icons/01.png"),
        name: "系统文件夹",
        id: 21,
      },
      // {
      //   nIcon: require("assets/images/disk/Icons/01.png"),
      //   hIcon: require("assets/images/disk/Icons/01.png"),
      //   name: "智能OCR",
      //   id: 7,
      // },

      // {
      //   nIcon: require("assets/images/disk/Icons/01.png"),
      //   hIcon: require("assets/images/disk/Icons/01.png"),
      //   name: "一键采集",
      //   id: 8,
      // },
      {
        nIcon: require("assets/images/disk/lately-n.png"),
        hIcon: require("assets/images/disk/lately-h.png"),
        name: "最近",
        id: 5,
      },
      {
        nIcon: require("assets/images/disk/doc-n.png"),
        hIcon: require("assets/images/disk/doc-h.png"),
        name: "文档",
        id: 1,
      },
      {
        nIcon: require("assets/images/disk/img-n.png"),
        hIcon: require("assets/images/disk/img-h.png"),
        name: "图片",
        id: 2,
      },
      {
        nIcon: require("assets/images/disk/video-n.png"),
        hIcon: require("assets/images/disk/video-h.png"),
        name: "视频",
        id: 3,
      },
      {
        nIcon: require("assets/images/disk/other-n.png"),
        hIcon: require("assets/images/disk/other-h.png"),
        name: "其他",
        id: 4,
      },

      // {
      //   nIcon: require("assets/images/disk/lock-n.png"),
      //   hIcon: require("assets/images/disk/lock-h.png"),
      //   name: "隐藏空间",
      //   id: 99,
      // },
    ],
  },

  {
    nIcon: require("assets/images/disk/Icons/007.png"),
    hIcon: require("assets/images/disk/Icons/007.png"),
    name: "回收站",
    id: 10,
  },
];
