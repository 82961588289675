<template>
  <div class="disk-search">
    <div class="searchBox-left">
      <el-select
        @change="handleSearch"
        v-model="valueSelect"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="disk-search-input">
      <input
        @keydown.enter="handleSearch"
        type="text"
        v-model="value"
        placeholder="请输入关键搜索词"
      />
    </div>
    <div class="searchBox-btn" @click="handleSearch">
      <i class="searchBox-btn-icon"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiskSearch',
  data() {
    return {
      value: '',
      // 默认选择
      valueSelect: '文件名',
      // 搜索左侧数组
      options: [
        {
          value: '文件名',
          label: '文件名',
        },
        // {
        //   value: '全文',
        //   label: '全文',
        // },
        // {
        //   value: '原创句子',
        //   label: '原创句子',
        // },
      ],
    }
  },
  methods: {
    handleSearch() {
      this.$emit('handleSearch', this.value ? this.value : '')
    },
  },
}
</script>

<style lang="scss" scoped>
.disk-search {
  @include flex-between;
  padding: 0 12px;
  margin: 10px 20px;
  width: calc(100% - 40px);
  height: 49px;
  border: 1px solid #eceff1;
  box-sizing: border-box;
  .searchBox-left {
    height: 26px;
    ::v-deep .el-select {
      width: 95px;
      height: 26px;
      .el-input el-input--suffix {
        height: 26px;
      }
      .el-input__inner {
        padding: 0 0 0 15px;
        border: none;
        height: 26px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 26px;
      }
      .el-input__suffix {
        top: 7px;
      }
      .is-focus .el-input__suffix {
        top: -8px;
      }
    }
  }
  .searchBox-btn {
    margin-right: 6px;
    padding: 0 20px;
    height: 34px;
    background: #ff6900;
    border-radius: 3px;
    cursor: pointer;
    display: flex;

    align-items: center;
    justify-content: center;
    .searchBox-btn-icon {
      width: 16.2px;
      height: 16.2px;
      color: #fff;
      @include backgroundGroup('../../assets/images/goldenSentence/search.png');
    }
  }
  .disk-search-input {
    flex: 1;
    input {
      width: 100%;
      padding: 0 15px;
      font-size: 12px;
      color: #66667f;
      border-left: 1px solid #e4e4e4;
      height: 26px;
      box-sizing: border-box;
      &::placeholder {
        font-size: $font-size-small;
        font-weight: 400;
        color: $color-text-gray-s;
      }
    }
  }
  .disk-search-btn {
    cursor: pointer;
    display: inline-block;
    width: 12px;
    height: 12px;
    @include backgroundGroup('~assets/images/disk/disk-search-btn.png');
  }
}
@media screen and(min-width: 1220px) {
  .disk-search {
    @include flex-between;
    padding: 0 18px;
    width: calc(100% - 40px);
    height: 49px;
    border: 1px solid #eceff1;
    box-sizing: border-box;
    .disk-search-input {
      flex: 1;
      input {
        width: 100%;
        padding: 0 8px;
        font-size: 14px;
        color: #66667f;
        box-sizing: border-box;
        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: $color-text-gray-s;
        }
      }
    }
    .disk-search-btn {
      cursor: pointer;
      display: inline-block;
      width: 15px;
      height: 15px;
      @include backgroundGroup('~assets/images/disk/disk-search-btn.png');
    }
  }
}
</style>
