<template>
  <div class="disk-sidebar-item">
    <div class="item">
      <div
        class="row-name"
        :class="{ active: sideBarData.id == $store.state.sidebarActive }"
        @click="handleItem(sideBarData.id, sideBarData.name)"
      >
        <i
          class="el-icon-caret-bottom"
          v-if="hasChildren"
          :class="{ 'arrow-icon-rotate': !open }"
          @click.stop="handleArrow(sideBarData.id)"
        ></i>
        <img
          :src="[
            sideBarData.id == $store.state.sidebarActive
              ? sideBarData.hIcon
              : sideBarData.nIcon,
          ]"
          alt=""
          width="14"
          height="14"
          v-else-if="sideBarData.nIcon"
        />
        {{ sideBarData.name }}
      </div>
      <div class="submenu" v-show="open" v-if="hasChildren">
        <disk-side-bar-item
          :sideBarData="item"
          v-for="item in sideBarData.children"
          :key="item.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['disk'],
  name: 'DiskSideBarItem',
  props: ['sideBarData'],
  data() {
    return {
      open: true,
    }
  },
  computed: {
    hasChildren: function () {
      return this.sideBarData.children && this.sideBarData.children.length
    },
  },
  created() {
    this.$store.commit('ChangeSideBarId', 0)
  },
  methods: {
    handleArrow() {
      if (this.hasChildren) {
        this.open = !this.open
      }
    },
    handleItem(id, value) {
      if (id == 20 || id == 21 || id == 6 || id == 11) {
        this.disk.uploadFilesNav = true
        this.disk.addFiles = true
      } else {
        this.disk.uploadFilesNav = false
        this.disk.addFiles = false
      }
      // if (
      //   value != "云文档" &&
      //   value != "网页采集文档" &&
      //   value != "溯源报告" &&
      //   value != "授权"
      // ) {
      //   this.disk.uploadFilesNav = false;
      // }

      if (value == '回收站') {
        this.disk.showDale = true
      } else {
        this.disk.showDale = false
      }
      this.disk.nubs1 = 2
      this.disk.MyItem = [{ id: '0', name: '我的网盘' }]
      if (id == 0) {
        this.disk.uploadFilesNav = true
        this.disk.nubs = 2
        this.disk.getDiskLists({
          id: 0,
          pageIndex: 1,
          pageSize: 20,
          sizeSort: '',
          timeSort: 'desc',
        })
      } else {
        this.disk.getSideBarType({
          name: value,
          id: id,
          pageIndex: 1,
          pageSize: 20,
        })
      }
      this.$store.commit('ChangeSideBarId', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.disk-sidebar-item {
  width: 100%;
  .row-name {
    position: relative;
    @include flex-start;
    padding-left: 38px;
    height: 40px;
    width: inherit;
    line-height: 40px;
    font-size: 14px;
    // font-family: PingFang SC;
    // font-weight: 700;
    color: #333333;
    ::v-deep .el-icon-caret-bottom {
      cursor: pointer;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      background: #fffaf3;
    }
    .arrow-icon-rotate {
      transform: translateY(-50%) rotate(180deg);
    }
    img {
      margin-right: 4px;
    }
  }
  @media screen and(min-width: 1220px) {
    .row-name {
      position: relative;
      @include flex-start;
      padding-left: 38px;
      height: 43px;
      width: inherit;
      line-height: 43px;
      font-size: 14px;
      // font-family: PingFang SC;
      // font-weight: 700;
      color: #333333;

      ::v-deep .el-icon-caret-bottom {
        cursor: pointer;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        background: #fffaf3;
      }
      .arrow-icon-rotate {
        transform: translateY(-50%) rotate(180deg);
      }
      img {
        margin-right: 6px;
        width: 14px;
        height: 14px;
      }
    }
  }
  .active {
    background: #ebf2ff;
    color: #ff6900;
    border-right: #ff6900 4px solid;
    background-color: #fffaf3;
  }
}
</style>
