<template>
  <div class="disk-side-bar">
    <!-- logo -->
    <!-- <div class="title" @click="evidence">智能网盘</div> -->
    <!-- 侧边栏 -->
    <div class="side-bar">
      <disk-side-bar-item
        :sideBarData="item"
        v-for="item in sideBarData"
        :key="item.id"
      />
    </div>
    <!-- 内存展示 -->
    <div class="vip_content">
      <!-- <div class="storage-detail">
        <div class="storage-proportion" ref="messageInfoTableRef">
          <img :src="require('assets/textnub.png')" alt="" />
          {{ nub }}
        </div>
      </div> -->
      <div
        v-for="item in vipList"
        :key="item.nub"
        :class="{
          currentLevel: item.level == $store.state.user_info.memberlevel,
        }"
        class="item"
        @click="memberRenewal(item.name)"
      >
        <img :src="item.icon" alt="" />
        <div class="text">{{ item.name }}</div>
        <div class="nub">{{ item.nub }}G</div>
      </div>
    </div>
    <!-- 存储空间 -->
    <!-- <div class="storage-space">
      <div class="progress-bar__outer">
        <div
          class="progress-inner"
          ref="progressinner"
          style="width: 50%"
        ></div>
      </div>
      <div class="storage-detail">
        <div class="storage-proportion" ref="messageInfoTableRef">
          {{ nub }}
        </div>

        <div class="addstorage" @click="addstorage" v-show="dilatation">
          扩容
        </div>
        <div class="addstorage1" @click="addstorage" v-show="!dilatation">
          <span>扩容</span
          ><img src="../../assets/images/disk/Icons/17.png" alt="" />
        </div>
      </div>
      <div class="fooder-bd">
        <el-switch
          @change="switchValue"
          v-model="value"
          active-color="#4587ff"
          inactive-color="#bcbcbc"
        >
        </el-switch>
        <span>自动智能解析</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import DiskSideBarItem from './DiskSideBarItem.vue'
import { sidebarItem } from '@/config/sidebar'
import { getToken } from 'utils/auth'
import { memoryFile, parse } from 'api/disk'
export default {
  components: { DiskSideBarItem },
  name: 'DiskSideBar',
  data() {
    return {
      value: false,
      nub: '',
      sideBarData: sidebarItem,
      dilatation: true,
      vipList: [
        {
          name: '普通会员:',
          level: 1,
          nub: 1,
          icon: require('assets/images/disk/icon_vip0.png'),
        },
        {
          name: 'VIP会员:',
          level: 2,
          nub: 20,
          icon: require('assets/images/disk/icon_vip1.png'),
        },
        {
          name: 'SVIP会员:',
          level: 3,
          nub: 50,
          icon: require('assets/images/disk/icon_vip2.png'),
        },
      ],
    }
  },
  created() {
    // this.getMemoryFile()
  },

  methods: {
    memberRenewal(name) {
      console.log(name)
      if (name == 'VIP会员:') {
        this.$router.push('/details/1')
      } else if (name == 'SVIP会员:') {
        this.$router.push('/details/1/2')
      }
    },
    // switchValue(val) {
    //   let id = {};
    //   if (val) {
    //     id = {
    //       id: 1,
    //     };
    //   } else {
    //     id = {
    //       id: 0,
    //     };
    //   }
    //   parse(id);
    // },
    // // 获取存储空间
    getMemoryFile() {
      memoryFile().then((res) => {
        this.nub = res.data.usedSpace + ' / ' + res.data.totalSpace
      })
    },
    evidence() {
      this.$parent.nubs = 2
      this.$store.commit('ChangeSideBarId', 0)
      ;(this.$parent.MyItem = [{ id: '0', name: '我的网盘' }]),
        this.$parent.getDiskLists({
          id: 0,
          pageIndex: 1,
          pageSize: 20,
          sizeSort: '',
          timeSort: 'desc',
        })
    },
    addstorage() {
      this.$parent.dialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.disk-side-bar {
  min-width: 180px;
  height: 100%;
  border-right: 1px solid #eceff1;
  .title {
    cursor: pointer;
    @include flex-center;
    width: 115px;
    height: 42px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: $color-text-black;
    border-bottom: 1px solid #eceff1;
    .disk-logo {
      margin-right: 5px;
      display: inline-block;
      width: 14px;
      height: 14px;
      @include backgroundGroup('~assets/images/disk/disklogo.png');
    }
  }
  .side-bar {
    cursor: default;
    height: calc(100% - 100px - 50px);
  }
  .vip_content {
    padding-left: 18px;
    // box-sizing: border-box;
    font-size: 12px;
    line-height: 32px;
    .item {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #666;
      &:hover {
        color: #4587ff;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .text {
        flex: 1;
      }
      .nub {
        width: 45px;
      }
    }

    .currentLevel {
      .text {
        color: #4587ff !important;
      }
      .nub {
        color: #4587ff !important;
      }
    }
    .storage-detail {
      @include flex-start;
      // margin-left: 20px;
      font-size: $font-size-small;
      .storage-proportion {
        min-width: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #a5a8b1;
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
          width: 15px;
        }
      }
    }
  }
  .storage-space {
    @include flex-center;
    flex-direction: column;
    height: 100px;
    .progress-bar__outer {
      position: relative;
      width: 88px;
      height: 4px;
      background: #eeeff4;
      border-radius: 3px;
      .progress-inner {
        height: inherit;
        border-radius: 3px;
        background: #4587ff;
      }
    }
    .storage-detail {
      margin-top: 3px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: $font-size-small;
      .storage-proportion {
        min-width: 20px;
        margin-top: 9px;
        font-size: 12px;
        font-weight: bold;
        color: #a5a8b1;
      }
      .addstorage {
        margin-top: 9px;
        cursor: pointer;
        margin-left: 5px;
        font-size: 12px;
        font-weight: bold;
        color: $color-text-active;
      }
      .addstorage1 {
        justify-content: left;
        width: 100%;
        margin-top: 9px;
        cursor: pointer;
        margin-left: 13px;
        font-size: 12px;
        font-weight: bold;
        color: $color-text-active;
        img {
          width: 6px;
          height: 11px;
          float: right;
          margin-right: 12px;
        }
      }
    }
  }
  .fooder-bd {
    width: 100%;
    padding-left: 8px;
    box-sizing: border-box;
    margin-top: 15px;
    display: flex;
    justify-content: left;

    span {
      margin-left: 5px;
      margin-top: 5px;
      font-size: 12px;
      color: #999;
    }
    ::v-deep .el-switch__core {
      width: 30px !important;
      height: 15px;
    }
    ::v-deep .el-switch__core:after {
      width: 11px;
      height: 11px;
    }
    /deep/ .el-switch.is-checked .el-switch__core::after {
      margin-left: -13px !important;
    }
  }
}
@media screen and (min-width: 1220px) {
  .disk-side-bar {
    width: 180px;
    height: 100%;
    border-right: 1px solid #eceff1;
    .title {
      cursor: pointer;
      @include flex-center;
      width: 150px;
      height: 52px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: $color-text-black;
      border-bottom: 1px solid #eceff1;
      // .disk-logo {
      //   margin-right: 5px;
      //   display: inline-block;
      //   width: 14px;
      //   height: 14px;
      //   @include backgroundGroup("~assets/images/disk/disklogo.png");
      // }
    }
    .side-bar {
      cursor: default;
      height: calc(100% - 150px - 50px);
    }
    .storage-space {
      @include flex-center;
      flex-direction: column;
      height: 150px;
      .progress-bar__outer {
        position: relative;
        width: 127px;
        height: 6px;
        background: #eeeff4;
        border-radius: 3px;
        .progress-inner {
          height: inherit;
          border-radius: 3px;
          background: #4587ff;
        }
      }
      .storage-detail {
        margin-top: 3px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 14px;
        .storage-proportion {
          min-width: 20px;
          margin-top: 9px;
          font-size: 14px;
          font-weight: bold;
          color: #a5a8b1;
        }
        .addstorage {
          margin-top: 12px;
          cursor: pointer;
          margin-left: 8px;
          font-size: 14px;
          font-weight: bold;
          color: $color-text-active;
        }
        .addstorage1 {
          justify-content: left;
          width: 100%;
          margin-top: 9px;
          cursor: pointer;
          margin-left: 13px;
          font-size: 14px;
          font-weight: bold;
          color: $color-text-active;
          img {
            width: 8px;
            height: 14px;
            float: right;
            margin-right: 12px;
          }
        }
      }
    }
    .fooder-bd {
      width: 100%;
      padding-left: 8px;
      box-sizing: border-box;
      margin-top: 10px;
      display: flex;
      justify-content: left;

      span {
        margin-left: 10px;

        margin-top: 10px;
        font-size: 14px;
        color: #999;
      }
      ::v-deep .el-switch__core {
        width: 40px !important;
        height: 20px;
        border-radius: 10px;
        margin-top: 15px;
      }
      ::v-deep .el-switch__core:after {
        width: 15px;
        height: 15px;
      }
      /deep/ .el-switch.is-checked .el-switch__core::after {
        margin-left: -17px !important;
      }
    }
  }
}
</style>
